import React from 'react';
import { useTranslation } from 'react-i18next';
import AppLeadingText from '../common/texts/appLeadingText';
import { isNotBuildPhase } from '../../utils/localStorage';

const isChrome = isNotBuildPhase && /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

const CameraError = () => {
	const { t } = useTranslation();
	return (
		<AppLeadingText>
			{isChrome && (
				<span>
					{t('cameraError.isChromeMsg')}
					{' '}
					<a
						href={t('cameraError.isChromeMsgLink')}
						rel="noreferrer"
						target="_blank"
					>
						{t('cameraError.isChromeMsgLinkText')}
					</a>
				</span>
			)}
			{!isChrome && <span>{t('cameraError.otherBrowserText')}</span>}
		</AppLeadingText>
	);
};

export default CameraError;
