import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import { isNotBuildPhase } from '../utils/localStorage';
import { clearCameraError, setCameraError } from '../utils/cookies';
import { fetchOrdersByTableCodeAction, fetchOrdersByTableIdAction } from '../state/orders.reducer';
import { clearTableDetailsAction, fetchTableDetailsByCodeAction } from '../state/table.reducer';
import { Loading, LoadingCircle } from './common/loading';
import CameraErrorWrapper from './qr/cameraErrorWrapper';
import AppPageHeader from './common/texts/appPageHeader';
import AppLeadingText from './common/texts/appLeadingText';

const StyledQrCodeWrapper = styled.div`
	.qr-code-reader {
		> section {
			> div {
				border: 12vw solid rgba(0, 0, 0, 0.7) !important;
				box-shadow: ${({ theme }) => theme.colors.brandPurple} 0px 0px 0px 5px inset !important;
			}
		}
	}
`;

const StyledLoadingWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
`;

const LoadedQrReader = loadable(() => import('react-qr-reader'));

const QRScanner = () => {
	const [cameraError, setCameraErrorState] = useState(false);
	const [scannerLoaded, setScannerLoaded] = useState(false);
	const order = useSelector((state) => state.order);
	const { loaderVisible } = order;
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const handleScan = (data) => {
		if (!data) {
			return;
		}
		clearCameraError();
		if (data.length === 6) {
			dispatch(clearTableDetailsAction());
			dispatch(fetchTableDetailsByCodeAction(data));
		}
	};

	const handleError = () => {
		if (isNotBuildPhase) {
			setCameraError();
			setCameraErrorState(true);
		}
	};

	useEffect(() => {
		if (isNotBuildPhase) {
			if (document.getElementsByClassName('qr-code-reader')[0]) {
				document.getElementsByClassName('qr-code-reader')[0].style.visibility = scannerLoaded ? 'visible' : 'hidden';
			}
		}
	}, [scannerLoaded]);

	if (cameraError) {
		return <CameraErrorWrapper />;
	}

	return (
		<StyledQrCodeWrapper>
			{loaderVisible && <Loading />}
			<AppPageHeader>{t('scanQr.header')}</AppPageHeader>
			<AppLeadingText>{t('scanQr.description')}</AppLeadingText>
			{!scannerLoaded && (
				<StyledLoadingWrapper>
					<LoadingCircle />
				</StyledLoadingWrapper>
			)}
			{isNotBuildPhase && (
				<LoadedQrReader
					className="qr-code-reader"
					delay={500}
					onError={handleError}
					onLoad={() => setScannerLoaded(true)}
					onScan={handleScan}
					style={{
						visibility: scannerLoaded ? 'visible' : 'hidden',
						width: '80%',
						margin: 'auto',
					}}
				/>
			)}
		</StyledQrCodeWrapper>
	);
};

export default QRScanner;
