import styled from 'styled-components';

const AppPageHeader = styled.h1`
  color: ${({ theme }) => theme.colors.brandPurple};
  font-size: 1.3rem;
  font-weight: 900;
  line-height: 1.7rem;
  margin: 0;
  padding: 30px 15px;
  text-align: center;
`;

export default AppPageHeader;
