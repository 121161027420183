import React from 'react';
import { useTranslation } from 'react-i18next';
import SEO from '../components/seo';
import QRScanner from '../components/QRScanner';
import { SwitchEnterMode } from '../components/qr/switchEnterMode';
import { AppContainer } from '../components/common/appContainer';

const QRCodePage = () => {
	const { t } = useTranslation();

	return (
		<>
			<SEO title={t('scanQr.title')} />
			<AppContainer>
				<QRScanner />
				<SwitchEnterMode />
			</AppContainer>
		</>
	);
};
export default QRCodePage;
