import styled from 'styled-components';

const AppLeadingText = styled.h2`
  color: ${({ theme }) => theme.colors.fontBlack};
  font-size: 1.57rem;
  font-weight: 300;
  line-height: 2rem;
  margin: 0;
  padding: ${({ paddingTop }) => (paddingTop ? '55px' : 0)} 15px 55px;
  text-align: center;
  b,
  strong,
  a {
    color: ${({ theme }) => theme.colors.brandPurple};
    font-weight: bold;
    text-decoration: none;
  }
`;

export default AppLeadingText;
