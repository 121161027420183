import React, { FC, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';
import { theme } from '../../constants/theme';
import AppText from './texts/appText';

const useStyles = makeStyles({
	loadingWrapper: {
		alignItems: 'center',
		backgroundColor: (props: { transparentBg: boolean }) => (props.transparentBg ? 'transparent' : 'rgba(255,255,255,0.8)'),
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'center',
		left: 0,
		padding: '0 30px',
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 9999999,
		p: {
			color: theme.colors.brandPurple,
			fontSize: '1.5rem',
			fontWeight: 'bold',
			lineHeight: '2rem',
			marginTop: 40,
			textAlign: 'center',
		},
	},
});

interface LoadingCircleProps {
    size?: number;
}

interface LoadingProps {
    transparentBg?: any;
    text?: string;
}

export const LoadingCircle: FC<LoadingCircleProps> = ({ size }) => {
	const [progress, setProgress] = React.useState(0);

	useEffect(() => {
		function tick() {
			setProgress((oldProgress) => (oldProgress >= 100 ? 0 : oldProgress + 1));
		}

		const timer = setInterval(tick, 20);
		return () => {
			clearInterval(timer);
		};
	}, []);
	return (
		<CircularProgress
			color="primary"
			size={size}
			thickness={6}
			value={progress}
		/>
	);
};

export const Loading: FC<LoadingProps> = ({ transparentBg, text }) => {
	const classes = useStyles(transparentBg);
	return (
		<div className={classes.loadingWrapper}>
			<LoadingCircle />
			{text && <AppText>{text}</AppText>}
		</div>
	);
};
