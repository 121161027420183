import React from 'react';
import { useTranslation } from 'react-i18next';
import AppPageHeader from '../common/texts/appPageHeader';
import AppLeadingText from '../common/texts/appLeadingText';
import { AppContainer } from '../common/appContainer';
import CameraError from './cameraError';

const CameraErrorWrapper = () => {
	const { t } = useTranslation();
	return (
		<AppContainer>
			<AppPageHeader>{t('cameraError.header')}</AppPageHeader>
			<AppLeadingText>{t('cameraError.description')}</AppLeadingText>
			<CameraError />
		</AppContainer>
	);
};

export default CameraErrorWrapper;
