import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { LINKS } from '../../constants/links';
import AppButton from '../common/button';
import { AppContainer } from '../common/appContainer';

const StyledSpace = styled.div`
  height: 92px;
  width: 100%;
`;

const StyledFixedWrapper = styled.div`
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  padding: 20px 0;
  text-align: center;
`;

const ToggleButton = styled(AppButton)`
  min-width: initial !important;
  width: 50% !important;
  &:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  &:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  &:hover,
  &:active {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.brandPurple};
  }
  &.active {
    background-color: ${({ theme }) => theme.colors.brandPurple};
    color: #fff;
  }
`;

export const SwitchEnterMode = () => {
	const { t } = useTranslation();
	return (
		<StyledSpace>
			<StyledFixedWrapper>
				<AppContainer>
					<StyledButtonWrapper>
						<ToggleButton
							activeClassName="active"
							as={Link}
							to={LINKS.SCAN_QR}
						>
							{t('enterCode.scanCodeQrButton')}
						</ToggleButton>
						<ToggleButton
							activeClassName="active"
							as={Link}
							to={LINKS.ENTER_CODE}
						>
							{t('scanQr.enterTableId')}
						</ToggleButton>
					</StyledButtonWrapper>
				</AppContainer>
			</StyledFixedWrapper>
		</StyledSpace>
	);
};
