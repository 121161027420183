import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	root: {
		boxSizing: 'border-box',
		display: 'block',
		margin: 'auto',
		padding: '0 16px',
		width: '100%',
		'@media (min-width: 600px)': {
			padding: '0px 24px',
		},
	},
});

export const AppContainer: FC = ({ children }) => {
	const classes = useStyles();
	return <div className={classes.root}>{children}</div>;
};
